import React,{ useEffect, useState, useRef } from 'react'
import { Form, Input, Button, Card, Comment, Avatar, message } from 'antd';
import MindService from '../../service/mind'
import { UserAuth } from '../../hooks/Auth'

import './index.css'

const Home = () => {
  const fromRef = useRef(null)
  const [mindList, setMindList] = useState([]);
  const { user={} } = UserAuth()
  const getMindList = () =>{
    MindService.getList().then((res) => {
      const list = res.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      setMindList(list);
    })
  }

  const onFinish = (values) => {
    
    MindService.add({...values, email: user.email}).then((res)=>{
      message.success('success')
      fromRef.current.resetFields()
      getMindList()
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    getMindList()
  }, []);

  return (
    <div className='wrapper'>
      <div className='content'>
        <Card>
          <Form
            name="basic"
            ref={fromRef}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label=""
              name="text"
              rules={[{ required: true, message: 'Please input text!' }]}
            >
              <Input.TextArea placeholder="What's on your mind?" showCount maxLength={80} />
            </Form.Item>

            <Form.Item >
              <Button type="primary" htmlType="submit">
                cluck
              </Button>
            </Form.Item>
          </Form>

        </Card>

        <div className='comment-list'>
        { mindList.map(item=>{
          return <Card key={item.id}>
            <Comment
              author={<a>{item.email || 'Han Solo'}</a>}
              avatar={<Avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />}
              content={
                <p>
                  {item.text}
                </p>
              }
            />
          </Card>
        }) }
        </div>
        
        
      </div>
    </div>
  )
}

export default Home
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAgiOOLaO2kx95mkh2wuQlBER-Hp2u9lCI",
  authDomain: "my-project-22172.firebaseapp.com",
  projectId: "my-project-22172",
  storageBucket: "my-project-22172.appspot.com",
  messagingSenderId: "558901715930",
  appId: "1:558901715930:web:500301d70fa800c4da16e3",
  measurementId: "G-DB40FF96GB"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);

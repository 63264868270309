import React, { useEffect, useState } from 'react'
import { Layout, Menu, Button, PageHeader, Avatar,  } from 'antd';
import { HomeOutlined, UsergroupDeleteOutlined, HeartOutlined, SettingOutlined } from '@ant-design/icons';

import { useNavigate } from 'react-router-dom'
import { UserAuth } from '../../hooks/Auth'
import _ from 'lodash';

import './index.css'
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;


const Layouter = (props) => {
  const { logOut, user={} } = UserAuth()
  const navigate = useNavigate()
  return(
  <Layout>
    <Header className="header">
       <PageHeader
        extra={[
          <div key='username' className='username'>{ _.get(user,'email') }</div>,
          <Button key='logout' onClick={() => logOut()}> Logout</Button> 
        ]}
      />      
    </Header>
    
      <Layout
      >
        <Content
          className="site-layout-background"
          style={{
            padding: '24px',
            margin: 0,
            minHeight: 280,
          }}
        >
          {props.children}
        </Content>
      </Layout>
  </Layout>
)};

export default Layouter
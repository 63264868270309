import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UserAuth } from './hooks/Auth'

export default function AuthRoute () {

  const { user } = UserAuth()
  return (
    user ? <Outlet /> : <Navigate to="/login" />
  )
}
import React, { useEffect } from 'react';
import { GoogleButton } from 'react-google-button';
import { UserAuth } from '../../hooks/Auth';
import { useNavigate } from 'react-router-dom';
import './index.css'
const Signin = () => {
  const { googleSignIn, user } = UserAuth();
  const navigate = useNavigate();
  const handleGoogleSignIn = async () => {
    console.log('click')
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

    useEffect(() => {
    if (user != null && user) {
      navigate('/home');
    }
  }, [user]);


  return (
    <div className='login-container'>
      <h1>Sign in</h1>
      <div className=''>
        <GoogleButton onClick={handleGoogleSignIn} />
      </div>
    </div>
  );
};

export default Signin;

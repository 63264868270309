import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthRoute from './AuthRoute'
import Layout from './pages/layout'
import Login from './pages/login'
import Home from './pages/home'

import "./App.css";
function App() {

  return (
    <div className="App">
    
      <Routes>

        <Route path='/home' element={<AuthRoute />} >
          <Route path='/home' element={<Layout><Home /></Layout>} />
        </Route>

        <Route path='/login' element={<Login />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
      
    </div>
  );
}

export default App;

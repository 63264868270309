import { db } from "../firebase-config";

import {
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore";

const mindCollection = collection(db, "mind");

const MindService  = {
  add:(data) => {
    return addDoc(mindCollection, data);
  },
  getList:() => {
    return getDocs(mindCollection);
  },
}

export default MindService;

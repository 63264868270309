import { useState } from 'react'
import cookie from 'react-cookies'

export function useLocalStorage(keyName) {
  const value = cookie.load(keyName)
  const [storedValue, setStoredValue] = useState(value)

  const setValue = (newValue) => {
    try {
      if (newValue) {
        cookie.save(keyName, JSON.stringify(newValue), { path: '/' })
      } else {
        cookie.remove(keyName, { path: '/' })
      }
    } catch (err) {
      console.error(err)
    }
    setStoredValue(newValue)
  }

  return [storedValue, setValue]
}
